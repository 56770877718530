<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>{{
        $t("message.accessControl.accessControl")
      }}</b-card-title>
      <b-card-sub-title>{{
        $t("message.accessControl.accessPermissions")
      }}</b-card-sub-title>
    </b-card-body>

    <b-card class="text-center my-17" v-if="spinnerLoading">
      <b-spinner class="align-middle"></b-spinner>
      <strong> {{ $t("message.load") }}...</strong>
    </b-card>

    <b-card
      class="d-flex justify-content-between align-items-center pb-0"
      v-else
    >
      <b-row>
        <table class="accessControl-table">
          <thead>
            <tr>
              <th class="stick">
                {{ $t("message.accessControl.functionalities") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(feature, index) in featureList" :key="index.id">
              <td
                :title="feature.description"
                @mouseover="getFeatureLine(index)"
                v-bind:class="[selectCell === index ? 'titleBackground' : '']"
              >
                <!-- {{ limitCharacters(feature.name) }} -->
                {{ feature.name }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="accessControl-table">
          <thead>
            <tr>
              <th style="text-align: center" class="stick">
                {{ $t("message.accessControl.administrators") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(administrator, index) in accessControlAdministrators"
              :key="index.id"
            >
              <td
                v-bind:class="[
                  selectCell === index
                    ? 'tableCellSelected'
                    : administrator.status
                    ? 'tableCellActive'
                    : 'tableCellDisabled',
                ]"
                @click="
                  accessControl(
                    index,
                    administrator.feature_id,
                    administrator.status,
                    'accessControlAdministrators'
                  )
                "
                @mouseover="getFeatureLine(index)"
              >
                <b v-if="administrator.status"
                  >{{ $t("message.employees.active") }}
                </b>
                <b v-else>{{ $t("message.accessControl.inactive") }}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="accessControl-table">
          <thead>
            <tr>
              <th style="text-align: center" class="stick">
                {{ $t("message.accessControl.administrative") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(administrative, index) in accessControlAdministrative"
              :key="index.id"
            >
              <td
                v-bind:class="[
                  selectCell === index
                    ? 'tableCellSelected'
                    : administrative.status
                    ? 'tableCellActive'
                    : 'tableCellDisabled',
                ]"
                @click="
                  accessControl(
                    index,
                    administrative.feature_id,
                    administrative.status,
                    'accessControlAdministrative'
                  )
                "
                @mouseover="getFeatureLine(index)"
              >
                <b v-if="administrative.status"
                  >{{ $t("message.employees.active") }}
                </b>
                <b v-else>{{ $t("message.accessControl.inactive") }}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="accessControl-table">
          <thead>
            <tr>
              <th style="text-align: center" class="stick">
                {{ $t("message.accessControl.director") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(director, index) in accessControlDirector"
              :key="index.id"
            >
              <td
                v-bind:class="[
                  selectCell === index
                    ? 'tableCellSelected'
                    : director.status
                    ? 'tableCellActive'
                    : 'tableCellDisabled',
                ]"
                @click="
                  accessControl(
                    index,
                    director.feature_id,
                    director.status,
                    'accessControlDirector'
                  )
                "
                @mouseover="getFeatureLine(index)"
              >
                <b v-if="director.status"
                  >{{ $t("message.employees.active") }}
                </b>
                <b v-else>{{ $t("message.accessControl.inactive") }}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="accessControl-table">
          <thead>
            <tr>
              <th style="text-align: center" class="stick">
                {{ $t("message.accessControl.manager") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(manager, index) in accessControlManager"
              :key="index.id"
            >
              <td
                v-bind:class="[
                  selectCell === index
                    ? 'tableCellSelected'
                    : manager.status
                    ? 'tableCellActive'
                    : 'tableCellDisabled',
                ]"
                @click="
                  accessControl(
                    index,
                    manager.feature_id,
                    manager.status,
                    'accessControlManager'
                  )
                "
                @mouseover="getFeatureLine(index)"
              >
                <b v-if="manager.status"
                  >{{ $t("message.employees.active") }}
                </b>
                <b v-else>{{ $t("message.accessControl.inactive") }}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="accessControl-table">
          <thead>
            <tr>
              <th style="text-align: center" class="stick">
                {{ $t("message.accessControl.financial") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(financial, index) in accessControlFinancial"
              :key="index.id"
            >
              <td
                v-bind:class="[
                  selectCell === index
                    ? 'tableCellSelected'
                    : financial.status
                    ? 'tableCellActive'
                    : 'tableCellDisabled',
                ]"
                @click="
                  accessControl(
                    index,
                    financial.feature_id,
                    financial.status,
                    'accessControlFinancial'
                  )
                "
                @mouseover="getFeatureLine(index)"
              >
                <b v-if="financial.status"
                  >{{ $t("message.employees.active") }}
                </b>
                <b v-else>{{ $t("message.accessControl.inactive") }}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="accessControl-table">
          <thead>
            <tr>
              <th style="text-align: center" class="stick">
                {{ $t("message.user.user") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in accessControlUser" :key="index.id">
              <td
                v-bind:class="[
                  selectCell === index
                    ? 'tableCellSelected'
                    : user.status
                    ? 'tableCellActive'
                    : 'tableCellDisabled',
                ]"
                @click="
                  accessControl(
                    index,
                    user.feature_id,
                    user.status,
                    'accessControlUser'
                  )
                "
                @mouseover="getFeatureLine(index)"
              >
                <b v-if="user.status">{{ $t("message.employees.active") }} </b>
                <b v-else>{{ $t("message.accessControl.inactive") }}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="accessControl-table">
          <thead>
            <tr>
              <th style="text-align: center" class="stick">
                {{ $t("message.accessControl.application") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(application, index) in accessControlApplication"
              :key="index.id"
            >
              <td
                v-bind:class="[
                  selectCell === index
                    ? 'tableCellSelected'
                    : application.status
                    ? 'tableCellActive'
                    : 'tableCellDisabled',
                ]"
                @click="
                  accessControl(
                    index,
                    application.feature_id,
                    application.status,
                    'accessControlApplication'
                  )
                "
                @mouseover="getFeatureLine(index)"
              >
                <b v-if="application.status"
                  >{{ $t("message.employees.active") }}
                </b>
                <b v-else>{{ $t("message.accessControl.inactive") }}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="accessControl-table">
          <thead>
            <tr>
              <th style="text-align: center" class="stick">
                {{ $t("message.accessControl.customer") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(customer, index) in accessControlCustomer"
              :key="index.id"
            >
              <td
                v-bind:class="[
                  selectCell === index
                    ? 'tableCellSelected'
                    : customer.status
                    ? 'tableCellActive'
                    : 'tableCellDisabled',
                ]"
                @click="
                  accessControl(
                    index,
                    customer.feature_id,
                    customer.status,
                    'accessControlCustomer'
                  )
                "
                @mouseover="getFeatureLine(index)"
              >
                <b v-if="customer.status"
                  >{{ $t("message.employees.active") }}
                </b>
                <b v-else>{{ $t("message.accessControl.inactive") }}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="accessControl-table">
          <thead>
            <tr>
              <th style="text-align: center" class="stick">
                {{ $t("message.accessControl.commercial") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(commercial, index) in accessControlCommercial"
              :key="index.id"
            >
              <td
                v-bind:class="[
                  selectCell === index
                    ? 'tableCellSelected'
                    : commercial.status
                    ? 'tableCellActive'
                    : 'tableCellDisabled',
                ]"
                @click="
                  accessControl(
                    index,
                    commercial.feature_id,
                    commercial.status,
                    'accessControlCommercial'
                  )
                "
                @mouseover="getFeatureLine(index)"
              >
                <b v-if="commercial.status"
                  >{{ $t("message.employees.active") }}
                </b>
                <b v-else>{{ $t("message.accessControl.inactive") }}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </b-row>
    </b-card>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BButton,
  BRow,
  BCol,
  BTooltip,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import CharacterLimit from "@core/utils/characterLimit";
import getHeader from "@core/utils/auth";
import store from "@/store";

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BRow,
    BCol,
    BTooltip,
    BSpinner,
  },

  data() {
    return {
      accessControlAdministrative: [],
      accessControlFinancial: [],
      accessControlManager: [],
      accessControlUser: [],
      accessControlDirector: [],
      accessControlAdministrators: [],
      accessControlApplication: [],
      accessControlCustomer: [],
      accessControlCommercial: [],

      accessControlList: [],
      featureList: [],
      accessControlUpdatedList: [],

      selectCell: 0,
      userData: store.state.user.userData,

      spinnerLoading: false,
    };
  },

  async created() {
    if (this.userData.systemRole === "ROLE_ADMIN") {
      this.spinnerLoading = true;
      await this.accessControlData(); //Lista padrão

      await axios
        .get(`${URL_API}feature`, { headers: getHeader(this.userData) })
        .then((response) => {
          if (response.data.length > 0) {
            this.featureList = response.data;
          }
        });

      await this.loadAccessControlList(); //Lógica para conferir dados
      this.spinnerLoading = false;
    }
  },

  methods: {
    // limitCharacters(value) {
    //   return CharacterLimit(value, 2);
    // },

    getFeatureLine(index) {
      this.selectCell = index;
    },

    async accessControlData() {
      await axios
        .get(`${URL_API}accessControl`, { headers: getHeader(this.userData) })
        .then((response) => {
          if (response.data != null) {
            this.accessControlList = response.data;
          }
        });
    },

    async accessControlDataRefresh() {
      await axios
        .get(`${URL_API}accessControl`, { headers: getHeader(this.userData) })
        .then((response) => {
          if (response.data != null) {
            this.accessControlList = response.data;
            this.accessControlAdministrative =
              response.data.accessControlAdministrative;
            this.accessControlFinancial = response.data.accessControlFinancial;
            this.accessControlManager = response.data.accessControlManager;
            this.accessControlUser = response.data.accessControlUser;
            this.accessControlDirector = response.data.accessControlDirector;
            this.accessControlAdministrators =
              response.data.accessControlAdministrators;
            this.accessControlApplication =
              response.data.accessControlApplication;
            this.accessControlCustomer = response.data.accessControlCustomer;
            this.accessControlCommercial = response.data.accessControlCommercial;
          }
        });
    },

    accessControl(index, feature_id, status, list) {
      if (list == "accessControlAdministrative") {
        if (this.accessControlAdministrative[index].feature_id == feature_id) {
          if (status == true) {
            this.accessControlAdministrative[index].status = false;
          } else {
            this.accessControlAdministrative[index].status = true;
          }
        }
      }
      if (list == "accessControlFinancial") {
        if (this.accessControlFinancial[index].feature_id == feature_id) {
          if (status == true) {
            this.accessControlFinancial[index].status = false;
          } else {
            this.accessControlFinancial[index].status = true;
          }
        }
      }
      if (list == "accessControlManager") {
        if (this.accessControlManager[index].feature_id == feature_id) {
          if (status == true) {
            this.accessControlManager[index].status = false;
          } else {
            this.accessControlManager[index].status = true;
          }
        }
      }
      if (list == "accessControlUser") {
        if (this.accessControlUser[index].feature_id == feature_id) {
          if (status == true) {
            this.accessControlUser[index].status = false;
          } else {
            this.accessControlUser[index].status = true;
          }
        }
      }
      if (list == "accessControlDirector") {
        if (this.accessControlDirector[index].feature_id == feature_id) {
          if (status == true) {
            this.accessControlDirector[index].status = false;
          } else {
            this.accessControlDirector[index].status = true;
          }
        }
      }
      if (list == "accessControlAdministrators") {
        if (this.accessControlAdministrators[index].feature_id == feature_id) {
          if (status == true) {
            this.accessControlAdministrators[index].status = false;
          } else {
            this.accessControlAdministrators[index].status = true;
          }
        }
      }
      if (list == "accessControlApplication") {
        if (this.accessControlApplication[index].feature_id == feature_id) {
          if (status == true) {
            this.accessControlApplication[index].status = false;
          } else {
            this.accessControlApplication[index].status = true;
          }
        }
      }
      if (list == "accessControlCustomer") {
        if (this.accessControlCustomer[index].feature_id == feature_id) {
          if (status == true) {
            this.accessControlCustomer[index].status = false;
          } else {
            this.accessControlCustomer[index].status = true;
          }
        }
      }
      if (list == "accessControlCommercial") {
        if (this.accessControlCommercial[index].feature_id == feature_id) {
          if (status == true) {
            this.accessControlCommercial[index].status = false;
          } else {
            this.accessControlCommercial[index].status = true;
          }
        }
      }

      this.accessControlUpdatedList = [
        {
          accessControlAdministrative: this.accessControlAdministrative,
          accessControlFinancial: this.accessControlFinancial,
          accessControlManager: this.accessControlManager,
          accessControlUser: this.accessControlUser,
          accessControlDirector: this.accessControlDirector,
          accessControlAdministrators: this.accessControlAdministrators,
          accessControlApplication: this.accessControlApplication,
          accessControlCustomer: this.accessControlCustomer,
          accessControlCommercial: this.accessControlCommercial,
        },
      ];

      axios({
        method: "put",
        url: `${URL_API}accessControl`,
        headers: getHeader(this.userData),
        data: this.accessControlUpdatedList[0],
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },

    async loadAccessControlList() {
      var lengthControl = 0;
      var lengthFeature = this.featureList.length;

      if (this.accessControlList.length != 0) {
        lengthControl =
          this.accessControlList.accessControlAdministrative.length;
      }

      if (this.accessControlList.length == 0) {
        //cria do 0
        var accessControlList = [];
        for (var i = 0; i < this.featureList.length; i++) {
          accessControlList.push({
            feature_id: this.featureList[i].feature_id,
            status: false,
          });
        }

        var newList = [];
        newList = [
          {
            accessControlAdministrative: accessControlList,
            accessControlFinancial: accessControlList,
            accessControlManager: accessControlList,
            accessControlUser: accessControlList,
            accessControlDirector: accessControlList,
            accessControlAdministrators: accessControlList,
            accessControlApplication: accessControlList,
            accessControlCustomer: accessControlList,
            accessControlCommercial: accessControlList,
          },
        ];

        axios({
          method: "post",
          url: `${URL_API}accessControl`,
          headers: getHeader(this.userData),
          data: newList[0],
        })
          .then((response) => {
            this.accessControlDataRefresh();
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
          });
      }

      if (
        this.accessControlList.length != 0 &&
        lengthControl != lengthFeature
      ) {
        //preciso conferir se add ou remover funcionalidade
        this.accessControlAdministrative =
          this.accessControlList.accessControlAdministrative;
        this.accessControlFinancial =
          this.accessControlList.accessControlFinancial;
        this.accessControlManager = this.accessControlList.accessControlManager;
        this.accessControlUser = this.accessControlList.accessControlUser;
        this.accessControlDirector =
          this.accessControlList.accessControlDirector;
        this.accessControlAdministrators =
          this.accessControlList.accessControlAdministrators;
        this.accessControlApplication =
          this.accessControlList.accessControlApplication;
        this.accessControlCustomer =
          this.accessControlList.accessControlCustomer;
        this.accessControlCommercial =
          this.accessControlList.accessControlCommercial;

        var oldList = [];
        for (
          var i = 0;
          i < this.accessControlList.accessControlAdministrative.length;
          i++
        ) {
          oldList.push(
            this.accessControlList.accessControlAdministrative[i].feature_id
          );
        }

        var newList = [];
        for (var i = 0; i < this.featureList.length; i++) {
          newList.push(this.featureList[i].feature_id);
        }

        var difference = newList
          .filter((x) => !oldList.includes(x))
          .concat(oldList.filter((x) => !newList.includes(x)));

        if (oldList.length > newList.length) {
          //remoção

          for (var i = 0; i < difference.length; i++) {
            for (var j = 0; j < this.accessControlAdministrative.length; j++) {
              if (
                this.accessControlAdministrative[j].feature_id == difference[i]
              ) {
                this.accessControlAdministrative.splice(j, 1);
              }
            }

            for (var j = 0; j < this.accessControlFinancial.length; j++) {
              if (this.accessControlFinancial[j].feature_id == difference[i]) {
                this.accessControlFinancial.splice(j, 1);
              }
            }

            for (var j = 0; j < this.accessControlManager.length; j++) {
              if (this.accessControlManager[j].feature_id == difference[i]) {
                this.accessControlManager.splice(j, 1);
              }
            }

            for (var j = 0; j < this.accessControlUser.length; j++) {
              if (this.accessControlUser[j].feature_id == difference[i]) {
                this.accessControlUser.splice(j, 1);
              }
            }

            for (var j = 0; j < this.accessControlDirector.length; j++) {
              if (this.accessControlDirector[j].feature_id == difference[i]) {
                this.accessControlDirector.splice(j, 1);
              }
            }

            for (var j = 0; j < this.accessControlAdministrators.length; j++) {
              if (
                this.accessControlAdministrators[j].feature_id == difference[i]
              ) {
                this.accessControlAdministrators.splice(j, 1);
              }
            }

            for (var j = 0; j < this.accessControlApplication.length; j++) {
              if (
                this.accessControlApplication[j].feature_id == difference[i]
              ) {
                this.accessControlApplication.splice(j, 1);
              }
            }

            for (var j = 0; j < this.accessControlCustomer.length; j++) {
              if (this.accessControlCustomer[j].feature_id == difference[i]) {
                this.accessControlCustomer.splice(j, 1);
              }
            }

            for (var j = 0; j < this.accessControlCommercial.length; j++) {
              if (this.accessControlCommercial[j].feature_id == difference[i]) {
                this.accessControlCommercial.splice(j, 1);
              }
            }
          }
        } else {
          //adição
          for (var i = 0; i < difference.length; i++) {
            this.accessControlAdministrative.push({
              feature_id: difference[i],
              status: false,
            });

            this.accessControlFinancial.push({
              feature_id: difference[i],
              status: false,
            });

            this.accessControlManager.push({
              feature_id: difference[i],
              status: false,
            });

            this.accessControlUser.push({
              feature_id: difference[i],
              status: false,
            });

            this.accessControlDirector.push({
              feature_id: difference[i],
              status: false,
            });

            this.accessControlAdministrators.push({
              feature_id: difference[i],
              status: false,
            });

            this.accessControlApplication.push({
              feature_id: difference[i],
              status: false,
            });

            this.accessControlCustomer.push({
              feature_id: difference[i],
              status: false,
            });

            this.accessControlCommercial.push({
              feature_id: difference[i],
              status: false,
            });
          }
        }

        var addList = [];

        addList = [
          {
            accessControlAdministrative: this.accessControlAdministrative,
            accessControlFinancial: this.accessControlFinancial,
            accessControlManager: this.accessControlManager,
            accessControlUser: this.accessControlUser,
            accessControlDirector: this.accessControlDirector,
            accessControlAdministrators: this.accessControlAdministrators,
            accessControlApplication: this.accessControlApplication,
            accessControlCustomer: this.accessControlCustomer,
            accessControlCommercial: this.accessControlCommercial,
          },
        ];

        axios({
          method: "put",
          url: `${URL_API}accessControl`,
          headers: getHeader(this.userData),
          data: addList[0],
        });
      }

      // Lista normal, sem alterações
      if (
        this.accessControlList.length != 0 &&
        lengthControl == lengthFeature
      ) {
        this.accessControlAdministrative =
          this.accessControlList.accessControlAdministrative;
        this.accessControlFinancial =
          this.accessControlList.accessControlFinancial;
        this.accessControlManager = this.accessControlList.accessControlManager;
        this.accessControlUser = this.accessControlList.accessControlUser;
        this.accessControlDirector =
          this.accessControlList.accessControlDirector;
        this.accessControlAdministrators =
          this.accessControlList.accessControlAdministrators;
        this.accessControlApplication =
          this.accessControlList.accessControlApplication;
        this.accessControlCustomer =
          this.accessControlList.accessControlCustomer;
        this.accessControlCommercial =
          this.accessControlList.accessControlCommercial;
      }
    },
  },

  setup() {
    return {
      active: false,
      text: "Editar",
    };
  },
};
</script>

<style>
.accessControl-table {
  border: solid 1px #ddeeee;
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 110px;
}
.accessControl-table thead th {
  background-color: #ddefef;
  border: solid 1px #ddeeee;
  color: #336b6b;
  padding: 10px;
  text-align: left;
}
.accessControl-table tbody td {
  border: solid 1px #ddeeee;
  color: #333;
  padding: 10px;
  text-shadow: 1px 1px 1px #fff;
}

.accessControl-table td:nth-child(1) {
  background-color: #fafafa;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  padding: 10px !important;
  border: 2px solid #f5f5f5;
  background: #f4f4f4;
  z-index: 1;
}

.tableCellActive {
  color: green !important;
  background: #e6ffe6 !important;
  text-align: center !important;
  cursor: pointer !important;
}

.tableCellDisabled {
  color: red !important;
  background: #ffe6e6 !important;
  text-align: center !important;
  cursor: pointer !important;
}

.tableCellSelected {
  background: rgb(230, 230, 230) !important;
  text-align: center !important;
  cursor: pointer !important;
}

.titleBackground {
  background: #b9b9c3 !important;
}

.my-17 {
  margin-top: 18.2rem;
  margin-bottom: 26.2rem;
}
</style>
